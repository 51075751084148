import React from "react";
import { StjMainContainerFontPatuaH2 } from "../../../layout/headings/headings.jsx";
import { Carousel, CarouselItem } from "../../../ui/carousel/carousel.jsx";
import { StJohnMainContainerWithBackgImage1 } from "../../../layout/containers/containers.jsx";
import "./parish_bulletin.scss";
import { routes } from "../../../../../site_links.jsx";

export const ParishBulletinSection = () => (
  <Carousel>
    <CarouselItem>
      <a
        href={routes.transfiguredLifePodcast}
        style={{ textDecoration: "none" }}
        target="_blank"
      >
        <StJohnMainContainerWithBackgImage1
          backgroundImage={"./assets_2/images/transfiguration_pc_blur.png"}
          overlayOpacity={0.7}
        >
          <div className="position-relative row d-flex align-items-lg-center st-bulletin-container">
            <section className="col-lg-4 d-flex align-items-center">
              <div className="text-center">
                <section style={{ color: "#fff" }}>
                  <h2 className="alexandria fs-3">
                    Check out the popular Orthodox YouTube channel 'The
                    Transfigured Life' with Fr. Ivanoff and Luther Menard
                  </h2>
                </section>
              </div>
            </section>

            <section className="col-lg-8">
              <figure>
                <img
                  src="./assets_2/images/transfigured_life.png"
                  alt="Transfigured Life Logo"
                  className="w-100 stj-shadow-1"
                />
              </figure>
            </section>
          </div>
        </StJohnMainContainerWithBackgImage1>
      </a>
    </CarouselItem>

    <CarouselItem>
      <StJohnMainContainerWithBackgImage1
        backgroundImage={"assets_2/banners/2023/wsj_article_image.jpeg"}
        overlayOpacity={0.8}
      >
        <div className="position-relative d-flex align-items-center row st-bulletin-container">
          <section className="row d-flex align-items-center justify-content-center">
            <div className="text-center">
              <section className="color-white d-flex flex-column justify-content-center align-items-center">
                <p className="mb-2 fs-5 font-patua">
                  We were recently featured on
                </p>
                <figure style={{ maxWidth: "25rem" }}>
                  <img
                    src="assets_2/banners/2023/wsj_logo.png"
                    alt="WSJ logo"
                    className="w-100"
                  />
                </figure>
              </section>
              <section className="fs-4 color-white mt-4">
                <StjMainContainerFontPatuaH2>
                  Eastern Orthodoxy Gains New Followers in America:
                  <br />
                  <span className="fst-italic">
                    Ancient Faith Is Drawing Converts With No Ties to Its
                    Historic Lands
                  </span>
                </StjMainContainerFontPatuaH2>

                <blockquote
                  style={{ fontSize: "1.2rem" }}
                  className="offset-lg-3 col-lg-6 mt-5"
                >
                  <div className="fst-italic">
                    "[Converts] bring in with them, not only [that] enthusiasm,
                    they bring with them a lot of knowledge about where they
                    come from, and then they can bridge that so that the parish
                    can engage in further outreach and try and bring the faith
                    to others”
                  </div>
                  <div className="text-end mt-2">- Fr. Jonathan Ivanoff</div>
                </blockquote>
                <p className="mt-4">
                  <a
                    target="_blank"
                    href="https://ocl.org/eastern-orthodoxy-gains-new-followers-in-america/"
                    className="text-decoration-none tithely-give-btn"
                    data-church-id="1637318"
                  >
                    Read Article
                  </a>
                </p>
              </section>
            </div>
          </section>
        </div>
      </StJohnMainContainerWithBackgImage1>
    </CarouselItem>
  </Carousel>
);
